import { Formik } from 'formik'
import React, { useState } from 'react'
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
  getRepos,
  AssetSearchParameters,
  performSearch,
  SearchType,
  SearchResult,
} from '../recoil/atoms/assetMonitor'
import './AssetBrowser.scss'

export const AssetBrowser = () => {
  const [
    searchParameters,
    setSearchParameters,
  ] = useState<AssetSearchParameters>()

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Gateway</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Asset Browser</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="text-center">Asset Browser</h1>
      <Formik
        onSubmit={(values) => {
          setSearchParameters({
            searchPattern: values.pattern,
            repoFilter: values.codebase !== '' ? values.codebase : undefined,
            searchType: values.searchType,
          })
        }}
        initialValues={{
          codebase: '',
          pattern: '',
          searchType: SearchType.All,
        }}
      >
        {({ handleSubmit, handleChange, values }) => (
          <Form noValidate inline onSubmit={handleSubmit}>
            <Form.Group className="mr-2">
              <Form.Control
                as="select"
                value={values.codebase}
                name="codebase"
                onChange={handleChange}
              >
                <option value="">Codebase (optional)</option>
                <React.Suspense fallback={<option>Loading...</option>}>
                  <RepoOptions />
                </React.Suspense>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="search.Pattern" className="mr-2 flex-fill">
              <Form.Control
                value={values.pattern}
                onChange={handleChange}
                name="pattern"
                className="flex-fill"
                placeholder="Type a Regex pattern to begin..."
              />
            </Form.Group>
            <Form.Group className="mr-2">
              <Form.Control
                as="select"
                value={values.searchType}
                name="searchType"
                onChange={handleChange}
              >
                {Object.values(SearchType).map((type) => (
                  <option key={type}>{type}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Button variant="primary" type="submit">
                Search
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
      <React.Suspense fallback={<div>Searching...</div>}>
        {searchParameters?.searchPattern !== undefined && (
          <AssetSearchResult {...searchParameters} />
        )}
      </React.Suspense>
      {searchParameters?.searchPattern === undefined && <ReposTable />}
    </Container>
  )
}

const AssetSearchResult = (props: AssetSearchParameters) => {
  // Get and sort results
  const searchResult = useRecoilValue(performSearch(props)).results.slice()
  searchResult.sort((a, b) => a.name.localeCompare(b.name))

  return (
    <Container>
      <Row className="mt-2 mb-2">
        <Col xs={12}>
          Showing {searchResult.length} results matching your search
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {searchResult.map((result) => (
            <AssetResult key={result.id} {...result} />
          ))}
        </Col>
      </Row>
    </Container>
  )
}

const AssetResult = (props: SearchResult) => {
  // Get repo URL
  const repos = useRecoilValue(getRepos)
  let selectedRepo
  for (const repo of repos) {
    if (repo.name === props.repo) {
      selectedRepo = repo
      break
    }
  }

  return (
    <Container className="AssetBrowser__AssetResult">
      <Row>
        <Col xs={3} className="AssetBrowser__AssetResult__Preview">
          {props.previewAsset !== undefined && (
            <AssetPreview
              searchType={props.resultType}
              id={props.previewAsset}
            />
          )}
        </Col>
        <Col xs={9}>
          <h5>{props.name}</h5>
          <div className="AssetBrowser__AssetResult__Details">
            {(selectedRepo !== undefined && selectedRepo.displayName) ||
              props.repo}{' '}
            | {selectedRepo?.assetLicense} |{' '}
            <RepoPathLink repo={props.repo} path={props.path}>
              {props.path}
            </RepoPathLink>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

type RepoPathLinkProps = {
  repo: string
  path: string
  children: React.ReactNode
}

const RepoPathLink = (props: RepoPathLinkProps) => {
  // Get repo URL
  const repos = useRecoilValue(getRepos)
  let selectedRepo
  for (const repo of repos) {
    if (repo.name === props.repo) {
      selectedRepo = repo
      break
    }
  }

  if (selectedRepo === undefined) {
    return <span>{props.children}</span>
  }

  // Strip out any @ from sprite states
  const strippedPath = props.path.split('@')[0]
  const repoUrl = selectedRepo.gitURL.substr(0, selectedRepo.gitURL.length - 4)
  const pathUrl = `${repoUrl}/blob/${selectedRepo.lastCheckedCommit}/${strippedPath}`

  return (
    <a className="repo-path-link" href={pathUrl}>
      {props.children}
    </a>
  )
}

type AssetPreviewProps = {
  id: string
  // eslint-disable-next-line react/no-unused-prop-types
  searchType: SearchType
}

const AssetPreview = (props: AssetPreviewProps) => {
  const { searchType } = props

  if (searchType === SearchType.Sprite) {
    return <SpritePreview {...props} />
  }
  if (searchType === SearchType.Audio) {
    return <AudioPreview {...props} />
  }

  return <SpritePreview {...props} />
}

const SpritePreview = (props: AssetPreviewProps) => (
  <img
    src={`${process.env.REACT_APP_AM_URL}/api/asset/${props.id}`}
    alt=""
    className="AssetBrowser__SpritePreview"
  />
)

const AudioPreview = (props: AssetPreviewProps) => (
  <audio
    controls
    preload="none"
    src={`${process.env.REACT_APP_AM_URL}/api/asset/${props.id}`}
  >
    <track kind="captions" />
  </audio>
)

const ReposTable = () => (
  <Table striped bordered hover className="mt-3">
    <thead>
      <tr>
        <th>Repo</th>
        <th>Asset License</th>
        <th>Link</th>
        <th>Last Checked Commit</th>
      </tr>
    </thead>
    <tbody>
      <React.Suspense
        fallback={
          <tr>
            <td colSpan={3}>Loading...</td>
          </tr>
        }
      >
        <Repos />
      </React.Suspense>
    </tbody>
  </Table>
)

type RepoLinkProps = {
  url: string
  commit?: string
  children: React.ReactNode
}

const RepoLink = ({ url, commit, children }: RepoLinkProps) => {
  let baseUrl = url.substring(0, url.length - 4)
  if (commit !== null) {
    baseUrl = `${baseUrl}/commit/${commit}`
  }
  return <a href={baseUrl}>{children}</a>
}

RepoLink.defaultProps = {
  commit: null,
}

const Repos = () => {
  const reposResolved = useRecoilValue(getRepos)
  return (
    <>
      {reposResolved.map((repo) => (
        <tr key={repo.gitURL}>
          <td>{repo.displayName}</td>
          <td>{repo.assetLicense}</td>
          <td>
            <RepoLink url={repo.gitURL}>GitHub</RepoLink>
          </td>
          <td>
            <RepoLink url={repo.gitURL} commit={repo.lastCheckedCommit}>
              {repo.lastCheckedCommit}
            </RepoLink>
          </td>
        </tr>
      ))}
    </>
  )
}

const RepoOptions = () => {
  const reposResolved = useRecoilValue(getRepos)
  return (
    <>
      {reposResolved.map((repo) => (
        <option key={repo.name} value={repo.name}>
          {repo.displayName}
        </option>
      ))}
    </>
  )
}
