import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './NavMenu.scss'
import { ReactComponent as Logo } from '../assets/ScrubbyBar.svg'

export const NavMenu = () => (
  <Navbar bg="light" expand="lg">
    <Container>
      <Navbar.Brand as={Link} to="/">
        <Logo height="30px" width="113px" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic=navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/">
            Home
          </Nav.Link>
          <Nav.Link as={Link} to="/search/player">
            Player Search
          </Nav.Link>
          <Nav.Link as={Link} to="/assets">
            Asset Browser
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)
