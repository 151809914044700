import React from 'react'
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap'
import { LatestRoundSummary } from '../components/latest-rounds/LatestRounds'

export const Home = () => (
  <Container>
    <Breadcrumb>
      <Breadcrumb.Item active>Gateway</Breadcrumb.Item>
    </Breadcrumb>
    <h1 className="text-center">Gateway</h1>
    <LatestRoundSummary />
    <Row className="justify-content-center">
      <Col xs="auto" sm={5} className="text-center">
        <p>
          Hello and welcome to Scrubby, a personal project by bobbahbrown.
          <br />
          <br />
          Feel free to discuss any issues, concerns, or ideas with me through
          Discord.
        </p>
      </Col>
    </Row>
  </Container>
)
