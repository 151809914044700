import React from 'react'
import { Container } from 'react-bootstrap'
import { Footer } from './Footer'
import { NavMenu } from './NavMenu'

export const Layout = (props: { children?: React.ReactNode }) => (
  <>
    <NavMenu />
    <Container fluid className="flex-grow-1 d-flex flex-column">
      {props.children}
      <Footer />
    </Container>
  </>
)

Layout.defaultProps = {
  children: [],
}
