import './App.scss'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { Home } from './pages/Home'
import { NotFound } from './pages/NotFound'
import { Layout } from './layout/Layout'
import { Round } from './pages/Round'
import { CKey } from './pages/CKey'
import { AssetBrowser } from './pages/AssetBrowser'

export const App = () => (
  <RecoilRoot>
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/assets" component={AssetBrowser} />
          <Route exact path="/round/:roundID([0-9]+)/files" component={Home} />
          <Route exact path="/round/:roundID([0-9]+)" component={Round} />
          <Route exact path="/ckey/:ckeyText" component={CKey} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  </RecoilRoot>
)
