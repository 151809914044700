import { selector } from 'recoil'

export interface DatabaseStatus {
  responseGenerated: Date
  rounds: number
  files: number
  latestRounds: ServerLatest[]
}

export interface ServerLatest {
  startTime: Date
  endTime: Date
  server: string
  round: number
}

export const dbStatus = selector<DatabaseStatus>({
  key: 'dbStatus',
  get: async () => {
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/dbstatus/stats`
    )
    return req.json()
  },
})
