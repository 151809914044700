import { selector, selectorFamily } from 'recoil'

export interface Repo {
  name: string
  displayName: string
  gitURL: string
  lastCheckedCommit: string
  assetLicense: string
  licenseNotice: string
}

export interface File {
  name: string
  path: string
  assets: Asset[] | null
  sprites: Sprite[] | null
}

export interface Asset {
  id: string
  assetType: string
  lastChanged: Date
  lastCommit: string
}

export interface Sprite {
  name: string
  dirs: number
  frames: number
  height: number
  width: number
  spriteFrames: SpriteFrame[] | null
  renderedAnimations: RenderedAnimation[] | null
}

export interface SpriteFrame {
  direction: number
  frame: number
  asset: Asset
}

export interface RenderedAnimation {
  direction: number
  asset: Asset
}

export interface SearchResultHeader {
  results: SearchResult[]
}

export interface SearchResult {
  id: string
  assetType: string
  resultType: SearchType
  name: string
  path: string
  repo: string
  repoId: string
  previewAsset: string
}

type RepoFileQuery = {
  repoName: string
  filePath: string
}

export enum AssetType {
  Unknown = 'Unknown',
  Audio = 'Audio',
  Image = 'Image',
  DMI = 'DMI',
  Frame = 'Frame',
  RenderedAnimation = 'RenderedAnimation',
}

export enum SearchType {
  Image = 'Image',
  Audio = 'Audio',
  DMI = 'DMI',
  Sprite = 'Sprite',
  File = 'File',
  All = 'All',
}

export type AssetSearchParameters = {
  searchType: SearchType
  searchPattern: string
  ignoreCase?: boolean
  repoFilterGuid?: string
  repoFilter?: string
}

export const getRepos = selector<Repo[]>({
  key: 'Repos',
  get: async () => {
    const req = await fetch(`${process.env.REACT_APP_AM_URL}/api/repo`)
    return req.json()
  },
})

export const getRepoFiles = selectorFamily<string[], string>({
  key: 'RepoFiles',
  get: (repoName) => async () => {
    const req = await fetch(
      `${process.env.REACT_APP_AM_URL}/api/repo/${repoName}`
    )
    return req.json()
  },
})

export const getRepoFileDetails = selectorFamily<File, RepoFileQuery>({
  key: 'FileDetails',
  get: (query) => async () => {
    const req = await fetch(
      `${process.env.REACT_APP_AM_URL}/api/repo/${query.repoName}/${query.filePath}`
    )
    return req.json()
  },
})

export const performSearch = selectorFamily<
  SearchResultHeader,
  AssetSearchParameters
>({
  key: 'AssetSearch',
  get: (query) => async () => {
    const req = await fetch(`${process.env.REACT_APP_AM_URL}/api/search`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(query),
    })
    return req.json()
  },
})
