import React from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import beecrying from '../assets/BeeCrying.png'
import './NotFound.scss'

export const NotFound = () => (
  <Container>
    <Breadcrumb>
      <Breadcrumb.Item active>The Great Unknown</Breadcrumb.Item>
    </Breadcrumb>
    <Row className="justify-content-center mb-5">
      <h1>404 - Not Found</h1>
    </Row>
    <Row>
      <Col sm="12" md="6" className="align-items-center d-flex">
        <div className="not_found_message">
          Oh dear, it looks like that link doesn't exist! Are you <i>sure</i>{' '}
          this was right...?
        </div>
      </Col>
      <Col
        sm="12"
        md="6"
        className="justify-content-center align-items-center d-flex"
      >
        <img className="crying_bee" alt="bee crying" src={beecrying} />
      </Col>
    </Row>
  </Container>
)
