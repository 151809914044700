import React from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import { Link, RouteComponentProps } from 'react-router-dom'

export const Round = (props: RouteComponentProps<{ roundID: string }>) => {
  const round: number = parseInt(props.match.params.roundID, 10)

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Gateway</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>R{round}</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="text-center">Round {round}</h1>
    </Container>
  )
}
