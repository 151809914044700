import React from 'react'

export const Footer = () => (
  <footer className="Footer__FooterDiv text-center">
    <hr />
    <p>
      <small>
        All content is provided without any guarantee, explicit or implied, of
        accuracy or permanant access.
        <br />
        &copy; 2021 - MelonMesa
      </small>
    </p>
  </footer>
)
