import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const RoundLink = (props: { round: number }) => (
  <OverlayTrigger
    overlay={
      <Tooltip id={`round-tooltip-${props.round}`}>
        This is round {props.round}
      </Tooltip>
    }
  >
    <Link to={`/round/${props.round}`}>{props.round}</Link>
  </OverlayTrigger>
)
