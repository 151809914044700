import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useRecoilValue } from 'recoil'
import { RoundLink } from '../roundlink/RoundLink'
import { dbStatus, ServerLatest } from '../../recoil/atoms/dbStatus'
import './LatestRounds.scss'

export const LatestRoundSummary = () => (
  <Row className="justify-content-center">
    <Col xs="auto" className="p-2 border rounded border-primary mb-4">
      <h6 className="LatestRounds__ServersHeader text-primary text-center">
        Latest Rounds
      </h6>
      <React.Suspense fallback={<div>Loading...</div>}>
        <LatestRoundSummaryResolver />
      </React.Suspense>
    </Col>
  </Row>
)

const LatestRoundSummaryResolver = () => {
  const dbStatusResolved = useRecoilValue(dbStatus)
  return (
    <>
      {dbStatusResolved.latestRounds.map((server) => (
        <LatestRounds key={server.server} data={server} />
      ))}
    </>
  )
}

const LatestRounds = (props: { data: ServerLatest }) => (
  <div className="LatestRounds__ServerRow">
    <span>{props.data.server}</span>
    <span className="LatestRounds__ServerRow__Dotter" />
    <RoundLink round={props.data.round} />
  </div>
)
